import { 
  getMiniWechatToken, 
  getH5WechatToken, 
  getMemberId, 
  getWechatRedirectUrl, 
  wechatAuthInfo 
} from '@/service/wechat.service'
import { setItem, getItem, delItem } from '@/utils'
import Matomo from 'wechat-matomo'
// #ifdef MP-WEIXIN
import { authLogin as getCode, getMobile, getAvatar } from '@/utils/nwechat'
// eslint-disable-next-line no-undef
const { miniProgram: { envVersion } } = uni.getAccountInfoSync()
// #endif

const state = {
  // token: getItem('token'),
  // info: getItem('user_info') || {},
  // chain_corp_id: getItem('chain_corp_id'),
  token: '',
  info: {},
  chain_corp_id: '',
  // 用户设备信息
  systemInfo: {}
}

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, {
      token: '',
      info: {},
      chain_corp_id: ''
    })
    delItem('token')
    delItem('user_info')
    delItem('chain_corp_id')
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    setItem('token', token)
  },
  SET_INFO: (state, info) => {
    state.info = info
    setItem('user_info', info)
  },
  SET_CHAIN_CROP_ID: (state, chain_corp_id) => {
    state.chain_corp_id = chain_corp_id
    setItem('chain_corp_id', chain_corp_id)
  },
  SET_SYSTEMINFO: (state, systemInfo) => {
    state.systemInfo = systemInfo
  }
}
const actions = {
  GET_TOKEN({ state, commit }) {
    return new Promise(async(resolve, reject) => {
      try {
        // #ifdef MP-WEIXIN
        const code = await getCode()
        const { data } = await getMiniWechatToken(code)

        handleWechatAuthInfo(data.has_phone, data.has_avatar)
        console.log('GET_TOKEN', data)
        commit('SET_TOKEN', data.token)
        commit('SET_INFO', data.user_info)
        commit('SET_CHAIN_CROP_ID', data.chain_corp_id)
        // 每次登录获取成员member_id
        const idRes = await getMemberId()
        if (idRes.code == 200) {
          setItem('member_id', idRes.data.member_id || '')
        }
        // 埋点记录用户id
        if (envVersion === 'release' && data.user_info.qw_userid) {
          Matomo.tracker.setUserId(data.user_info.qw_userid)
        }
        // #endif
        // #ifdef H5
        await canUserAccess(commit)
        // #endif
        resolve()
      } catch (error) {
        console.log(error)
        reject()
      }
    })
  }
}

async function handleWechatAuthInfo(has_phone, has_avatar) {
  let encryptedData, iv, avatar
  if (!has_phone) {
    const mobileResult = await getMobile()
    encryptedData = mobileResult.encryptedData || ''
    iv = mobileResult.iv || ''
  }
  if (!has_avatar) {
    const avatarResult = await getAvatar()
    avatar = avatarResult.avatar || ''
  }
  const body = {}
  if (!encryptedData && !iv && !avatar) return 
  encryptedData && (body.encryptedData = encryptedData)
  iv && (body.iv = iv)
  avatar && (body.avatar = avatar)
  await wechatAuthInfo(body)
}

async function canUserAccess(commit) {
  //企业微信登录授权  添加token有效性检测
  if (!state.token) {
    await authLogin(commit)
  }
}

const getUserInfo = async(loginCode, commit) => {
  try {
    const { data, code } = await getH5WechatToken(loginCode)
    if (code == 200) {
      commit('SET_TOKEN', data.token)
      commit('SET_INFO', data.user_info)
      commit('SET_CHAIN_CROP_ID', data.chain_corp_id)
      // 获取前当前成员的member_id 保存在本地
      const res = await getMemberId()
      if (res.code == 200) {
        setItem('member_id', res.data.member_id || '')
      }
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('getUserInfo error', error)
    return false
  }
}

const authLogin = async (commit) => {
  let url = location.href
  let urlarr = url.split('?')
  let code = ''

  if (urlarr[1]) {
      // 路径上没有 code 时需要将参数暂存到storage
      if (urlarr[1].indexOf('state=') === -1) {
        const regex = /^([^\?]*)\?(.*)$/;
        let query = regex.exec(url) || null
        // 若无问号 数据则为空
        const params_str = (query ? query[2] : '').trim()
        setItem('origin_params', params_str)
      }
      // console.log('urlarrurlarr', urlarr[1])
      let arr = urlarr[1].split('&')
      arr.forEach(item => {
          if (item.indexOf('code=') !== -1) {
              code = item.replace('code=', '')
          }
      })
  }
  if (/[\d+]/.test(String(code))) {
      let res = await getUserInfo(code, commit)
      let clearUrl = urlarr[0]
      location.href = clearUrl + '?' + getItem('origin_params')
  } else {
    let now_url = ''
    // 打开小程序页面特殊处理-因为location.href中含有两层路径和？
    if (location.href.indexOf('/pagesTool/openMinProgram/index') !== -1) {
      now_url = location.href.split('?')[0]
      return
    } else {
      now_url = location.href
    }
    const { data } = await getWechatRedirectUrl(
      now_url
    )
    console.log('h5_code', data)
    let newUrl = decodeURIComponent(data.redirect_url).replace('"', '')
    location.href = newUrl
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
