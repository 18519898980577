// vuex@next https://next.vuex.vuejs.org/
// vuex-persistedstate https://github.com/robinvdvleuten/vuex-persistedstate

import { createStore } from 'vuex'

import getters from './getters'
import user from './modules/user'
import screenCustomer from './modules/screenCustomer'

export default createStore({
  modules: {
    user,
		screenCustomer
  },
  getters,
  plugins: []
})
