import { createSSRApp } from 'vue'
import App from './App.vue'
import store from './store'
import { cdnUrl } from '@/service/base-url'
import mixin from './mixins/index'

// #ifdef MP-WEIXIN
import Matomo from 'wechat-matomo'
import router from './router/router' // 引入路由

const { miniProgram: { envVersion } } = uni.getAccountInfoSync()
// 1: 微客, 2: 唐门, 3: 奥斯芬, 4: 私域数科, 5: 不焦先生, 6: 微开, 7: 奢啡
// 8: 诚信果林, 9: 孙北北, 10: 天使医生, 11: 柚子, 12: 樊文花, 13: 伊丽汇, 14: 泰山啤酒, 17 洗脸猫
if (envVersion === 'release') {
  Matomo.initTracker('https://tongji.freshgood.cn/matomo.php', '17')
}
// #endif
// #ifdef H5
import '@/utils/matomo.js'
import Vconsole from 'vconsole'
if (process.env.APP_ENV == 'H5-dev' || process.env.APP_ENV == 'H5-test') {
  new Vconsole()
}
// #endif
import derective from './utils/derective'

export function createApp() {
  const app = createSSRApp(App)
  app.mixin(mixin)
  app.use(store)
  // #ifdef MP-WEIXIN
  app.use(router)
  // #endif
  app.config.globalProperties.$CDNURL = cdnUrl
  derective(app)
  return {
    app
  }
}