import $ajaxService from './http'
import store from '@/store/index.js'
/**
 * 获取微信 agen jssdk相关配置信息 https://paas-teamwork.yuque.com/docs/share/d55a3530-a3c9-4625-85cc-4f83cc094b63?#
 * @param  {Array} url  必填	使用的api列表
 * @param  {String} ticket_type 必填	当前url
 */
export const getWechatAgenSignature = ({ ticket_type, url }) =>
  $ajaxService.post('get_jsticket', {
    ticket_type,
    url
  })

/**
 * 获取微信 jssdk相关配置信息 https://paas-teamwork.yuque.com/docs/share/d55a3530-a3c9-4625-85cc-4f83cc094b63?#
 * @param  {Array} apis  必填	使用的api列表
 * @param  {Array} open_tag_list  选填	可不填 打开的标签列表
 * @param  {Boolean} debug  选填	是否开启debug 默认为false
 * @param  {Boolean} beta 选填	是否是beta版本 默认为false
 * @param  {String} url 必填	当前url
 * @param  {String} type 必填 	aggent 应用,enterprise 通用
 * @param  {String} chain_corp_id 必填 	当前环境所处企业
 */
export const getWechatSignature = ({
  apis,
  open_tag_list,
  debug,
  beta,
  url,
  type,
  chain_corp_id
}) =>
  $ajaxService.post('get_jssdk', {
    apis,
    open_tag_list,
    debug,
    beta,
    url,
    type,
    chain_corp_id
  },
  { closeIntercept: true }
  )
/**
 * 微信获取token
 * @param  {} code 企微code
 */
export const getMiniWechatToken = (code) =>
    $ajaxService.get(
        'guide/xcx/auth_login',
        { code }
    )
  
export const getH5WechatToken = (code) =>
    $ajaxService.get(
        'guide/auth_login',
        { code }
    )

/**
 * 用户授权信息
 * @param  {} encryptedData 包括敏感数据在内的完整用户信息的加密数据
 * @param  {} iv 加密算法的初始向量
 */
export const wechatAuthInfo = body =>
  $ajaxService.post(
    'guide/auth/xcx_decryptData',
    body
  )

/**
 * 判断是否是好友
 * @param  {} qw_userid 企微成员id
 * @param  {} qw_external_userid 企微客户id
 */

export const checkIsFriend = ({ qw_userid, qw_external_userid }) =>
  $ajaxService.post(
    'enterprise_wecode/member_customer_jump',
    {
      qw_userid,
      qw_external_userid
    }
  )

/**
 * 判断是否有对应群
 * @param  {} qw_userid 企微成员id
 * @param  {} qw_external_userid 企微客户id
 */

 export const checkIsGroup = ({ qw_chat_id }) =>
  $ajaxService.post('task/member_groupchat_jump', {
   qw_chat_id
  })

/**
* 记录导购获取导购memberid https://we-customer.w.eolink.com/home/api_studio/inside/api/detail?apiID=48351566&groupID=1832266&projectHashKey=XIQlMVI1213a36bc20d06967488fe804e9f801782c717ec&spaceKey=we-customer
*/
export const getMemberId = () =>
    $ajaxService.post('guide/get_member_id')

    
/**
 * H5微信授权重定向地址
 * @param  {} origin_url 跳转前的url
 */
export const getWechatRedirectUrl = (origin_url) => {
    store.commit('user/SET_TOKEN', '')
    return $ajaxService.get('guide/get_redirect_url', {
    //   params: {
    //     origin_url
    //   }
      origin_url
    })
  }