<script>
	import { mapMutations, mapActions  } from 'vuex'
	import { setItem, getItem } from '@/utils'
	// import {checkSession} from '@/utils/nwechart.js'
	export default {
		onLaunch: function() {
			console.warn('当前组件仅支持 uni_modules 目录结构 ，请升级 HBuilderX 到 3.1.0 版本以上！')
			console.log('App Launch')
			// #ifdef MP-WEIXIN
			this.updateApp()
			// #endif
			this.getSystemInfo()
			// if (!getItem('token')) {
			// 	this.qyLogin()
			// }
		},
		onShow: function() {
			console.log('wx.env', wx.env)
			console.log('App Show')
		},
		onHide: function() {
			console.log('App Hide')
		},
		methods: {
			...mapActions({
				getToken: 'user/GET_TOKEN'
			}),
			...mapMutations(['user/SET_SYSTEMINFO']),
			// 获取当前设备信息
			getSystemInfo() {
				const systemInfo = uni.getSystemInfoSync()
				// #ifdef H5
					this.getUserAgent(systemInfo)
				// #endif
				console.log('systemInfo', systemInfo, systemInfo.platform)
				this['user/SET_SYSTEMINFO'](systemInfo)
				setItem('platform', systemInfo.platform)
			},
			getUserAgent(systemInfo = {}) {
				var ua = navigator.userAgent.toLowerCase()
				if (ua.match(/wxwork/i) == 'wxwork') {
					systemInfo.userAgent = 'wxwork'
				} else if (ua.match(/micromessenger/i) == 'micromessenger'){
					systemInfo.userAgent = 'micromessenger'
				}
				console.log('getUserAgent', systemInfo)
			},
			// 企微code
			qyLogin() {
				this.getToken()
			},
			updateApp() {
				const updateManager = uni.getUpdateManager();
				updateManager.onCheckForUpdate(function (res) {});
				updateManager.onUpdateReady(function (res) {
					uni.showModal({
						title: '更新提示',
						content: '新版本已经准备好，是否重启应用？',
						success(res) {
							if (res.confirm) {
								updateManager.applyUpdate();
							}
						}
					});
				});
				updateManager.onUpdateFailed(function (res) {});
			}
		}
	}
</script>

<style lang="scss">
	/*每个页面公共css */

	// 自定义全局样式
	@import '@/assets/style/public.scss';
	@import '@/uni_modules/uni-scss/index.scss';
	@import '@/assets/style/reset.css';
	@import '@/static/customicons.css';

	// 设置整个项目的背景色
	page {
		font-size: 28rpx;
		background-color: #f7f7f7;
	}
	div, view {
		box-sizing: border-box;
	}
	button {
		padding: 0;
		margin: 0;
		font-size: 28rpx;
	}

	// #ifdef H5	
	uni-page-head {
		display: none;
	}
	// #endif
	
	// @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
	// 	page {
	// 		padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
	// 		padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
	// 	}
	// }
</style>
<style lang="scss">
	// 默认缩小checkbox
	checkbox {
		transform: scale(.85);
	}
	// 拥有round类名的checkbox圆角
	checkbox.round .wx-checkbox-input,
	checkbox.round .uni-checkbox-input {
		border-radius: 50%;
	}
	// 设置checkbox选中状态背景色
	checkbox[checked] .wx-checkbox-input,
	checkbox.checked .uni-checkbox-input {
		background-color: $theme-color !important;   //背景
		border-color: $theme-color !important;   //边框
		color: #FFFFFF !important;   //对勾
	}

	// 动态日历组件
	// .calendar-box {
	// 	 ::v-deep .van-calendar .van-calendar__month-title {
	// 		display: none !important;
	// 	}
	// 	::v-deep .van-calendar__selected-day {
	// 		border-radius: 50%;
	// 		width: 32px !important;
	// 		height: 32px !important;
	// 	}
	// 	::v-deep .van-calendar__header {
	// 		box-shadow: none;
	// 		height: 32px;
	// 		background: #f9fafb;
	// 		box-shadow: 0px 1px 1px 0px #edeef2;
	// 	}
	// }
</style>
