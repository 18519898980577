export default function derective(app) {
    // 下拉到底触发
    app.directive('box-loadmore', {
        mounted: (el, binding) => {
            // 获取element-ui定义好的scroll盒子
            const SCROLL_DOM = el
            // console.log('绑定的', SCROLL_DOM)
            SCROLL_DOM.addEventListener('scroll', function() {
                /**
                 * scrollHeight 获取元素内容高度(只读)
                 * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
                 * clientHeight 读取元素的可见高度(只读)
                 * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
                 * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
                 */
                console.log('滑动', this.scrollHeight, this.scrollTop, this.clientHeight)

                const condition = this.scrollHeight - this.scrollTop - 1 <= this.clientHeight
                if (condition) {
                    binding.value()
                    console.log('触底')
                }
            })
        }
    })
}