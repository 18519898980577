/**
 * 储存筛选客户的条件
 * 用于筛选客户页面和标签筛选页面
 */

const state = {
  startTime: '', // 开始时间
	endTime: '', // 结束时间
	platformType: '', // 平台类型 枚举值:{1: 微信用户 2: 企业微信用户}
	tagSubjectDataIdx: [], // 符合的用户id (由标签筛选出来的用户)
	headquartersLabel: [], // 选中的总部标签
	serveLabel: [], // 选中的服务标签
	headquartersLabelObj: [], // 选中的总部标签
	serveLabelObj: [], // 选中的服务标签
	conditionType: 'and' //选择标签属性的条件(or, and)
}

const mutations = {
  SET_DATA(state, data = {}) {
		for (let key in data) {
			state[key] = data[key]
		}
	},
	CLEAR_DATA() {
		state.startTime = '',
		state.endTime = '',
		state.platformType = '',
		state.tagSubjectDataIdx = [],
		state.headquartersLabel = [],
		state.serveLabel = [],
		state.conditionType = 'and'
	}
}

export default {
	namespaced: true,
  state,
  mutations
}